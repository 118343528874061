




import { Component, Vue, Prop } from "vue-property-decorator";
import ko from "knockout";
import koHtmlAndViewModel from "Components/curves/curves/curves";

@Component
export default class CurvesWrapper extends Vue {
  @Prop() curveModule!: string;
  instance: any = {};
  mounted(): void {
    const param = {
      curveModule: this.curveModule,
    };

    this.instance = new koHtmlAndViewModel.viewModel(param);
    const { template } = koHtmlAndViewModel;
    this.$el.innerHTML = template;
    ko.applyBindings(this.instance, this.$el);
  }
  beforeDestroy(): void {
    ko.cleanNode(this.$el);
    this.instance.dispose();
  }
}
